@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  &.active {
    display: flex;
  }
}
.modal-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); */
  background-color: black;
  z-index: 1;
  transform-origin: 0 50%;
  transform: scaleX(0);
}
.modal-inner {
  opacity: 0;
  position: relative;
  /* position: fixed;
  left: 50%;
  transform: translate(-50%, 0); */
  z-index: 2;
}
.modal-video {
  width: 100%;
  height: 100%;
  position: relative;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
  }
}
.modal-close {
  position: absolute;
  border-radius: 50%;
  border: 1px solid #7d7d7d;
  &:before,
  &:after {
    content: '';
    display: block;
    background-color: #fff;
    position: absolute;
  }
}

@media (--pc) {
  .modal-inner {
    width: 810px;
    height: 60%;
  }
  .modal-close {
    width: 70px;
    height: 70px;
    top: -110px;
    right: -36px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.5;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 30px;
      height: 4px;
      border-radius: 2px;
      top: 50%;
      left: 50%;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@media (--sp) {
  .modal-inner {
    width: 95%;
    height: 70%;
  }
  .modal-close {
    width: vw(90);
    height: vw(90);
    top: vw(-120);
    right: 0;
    cursor: pointer;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.5;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: vw(45);
      height: vw(8);
      border-radius: vw(4);
      top: 50%;
      left: 50%;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
