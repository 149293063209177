@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.hd {
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  background-color: #fff;
  &-logo {
    a {
      display: block;
    }
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-menu {
    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-shrink: 0;
      box-sizing: border-box;
    }
  }
  &-menu-r {
    display: flex;
    li {
      color: white;
      &:nth-of-type(1) {
        background-color: $base_blue;
      }
      /* &:nth-of-type(2) {
        background-color: $dark_gray;
      } */
    }
  }
}
@media (--pc) {
  .hd {
    &-inner {
      width: 100%;
      height: 75px;
      box-sizing: border-box;
    }
    &-logo {
      margin-left: 40px;
      @mixin alphaHover;
      img {
        width: 280px;
        /* height: 29px; */
      }
    }
    &-menu {
      display: flex;
      a {
        @mixin alphaHover;
      }
    }
    &-menu-l,
    &-menu-r {
      display: flex;
      flex-shrink: 0;
    }
    &-menu-l {
      li {
        a {
          padding-right: 30px;
        }
      }
    }
    &-menu-r {
      li {
        box-sizing: border-box;
        width: 130px;
        height: 75px;
        padding: 0 12px;
        font-size: rem(13);
      }
      /* .glyphs-download {
        font-size: rem(18);
      } */
      .glyphs-letter {
        font-size: rem(15);
      }
    }
  }
}
@media (--sp) {
  .hd {
    &-inner {
      position: relative;
      width: 100%;
      height: vw(120);
      box-sizing: border-box;
    }
    &-logo {
      margin-left: vw(30);
      img {
        width: vw(480);
        /* height: vw(49); */
      }
    }
    &-trigger {
      width: vw(60);
      height: vw(60);
      margin-right: vw(30);
      position: relative;
      span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $dark_gray;
        transition: transform 0.3s ease-in-out;
      }
      &:before,
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $dark_gray;
        transform-origin: 50% 50%;
        transition: transform 0.3s ease-in-out;
      }
      &:before {
        margin-bottom: vw(16);
      }
      &:after {
        margin-top: vw(16);
      }
      &.active {
        span {
          transform: translateY(vw(-2)) rotate(-45deg);
        }
        &:before {
          transform: translateY(vw(16)) rotate(45deg);
        }
        &:after {
          transform: scaleX(0);
        }
      }
    }
    &-menu {
      position: fixed;
      top: vw(120);
      left: 0;
      width: 100%;
      background-color: #fff;
      overflow: hidden;
      height: 0;
      max-height: 0;
      opacity: 0;
      transition: opacity 0.3s ease;
      &.active {
        height: 100%;
        opacity: 1;
        max-height: 9999px;
      }
    }
    &-menu-l {
      li {
        width: 100%;
        height: vw(140);
        border-top: 1px solid $light_gray;
        position: relative;
        &:after {
          content: '\f101';
          font-family: glyphs !important;
          font-style: normal;
          font-weight: normal !important;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: vw(28);
          color: $dark_gray;
          position: absolute;
          top: 50%;
          right: vw(30);
          transform: translate(0, -50%);
        }
        a {
          padding: 0 vw(30);
          box-sizing: border-box;
          align-items: flex-start;
          .en {
            font-size: vw(26);
          }
          .jp {
            font-size: vw(32);
          }
        }
      }
    }
    &-menu-r {
      width: 100%;
      li {
        width: 100%;
        height: vw(120);
        font-size: rem(13);
      }
      i {
        display: block;
        margin-right: vw(15);
      }
      /* .glyphs-download {
        font-size: vw(36);
      } */
      .glyphs-letter {
        font-size: vw(28);
      }
    }
  }
}
