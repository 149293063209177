@charset "utf-8";
@import './_config/_mixin.css';
@import './_config/_fonts.css';
@import './_config/_media.css';
@import './_config/_reset.css';
@import './_common_modules/_scrollEffect.css';
@import './_common_modules/_header.css';
@import './_common_modules/_footer.css';
@import './_common_modules/_modal.css';
@import url('https://fonts.googleapis.com/css?family=Poppins');
.pp {
  font-family: 'Poppins', sans-serif;
}
html {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka,
    'MS PGothic', arial, helvetica, sans-serif;
}
.blue {
  color: $base_blue;
}
.btn {
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  overflow: hidden;
  box-sizing: border-box;
  line-height: 1;
  i,
  span {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: '\f101';
    font-family: glyphs !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 2;
  }
  &-blue {
    background-color: $base_blue;
    &:before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &-black {
    background-color: $dark_gray;
    &:before {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.sec {
  background-color: #fff;
  &--blue {
    background-color: $light_blue;
  }
  &--gray {
    background-color: #f8f8f8;
  }
  &-subtl,
  &-tl {
    margin: 0 auto;
    text-align: center;
    font-weight: normal;
  }
  &-tl {
    overflow: hidden;
  }
}

@media (--pc) {
  .root {
    min-width: 1100px;
    overflow: hidden;
  }
  .sp {
    display: none !important;
  }

  #root {
    font-size: rem(14);
  }

  .sec {
    padding: 120px 0;
    &-subtl {
      font-size: rem(16);
      line-height: 1;
    }
    &-tl {
      font-size: rem(36);
      letter-spacing: 2px;
      line-height: 1.5;
      margin-top: 20px;
    }
    &-inner {
      width: 1000px;
      margin: 0 auto;
    }
  }

  .main-content {
    padding-top: 75px;
  }
  .btn {
    &:hover {
      &:before {
        transform: scaleX(1);
        transform-origin: left center;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform 0.3s ease-in-out;
      transform-origin: right center;
    }
  }
}

@media (--sp) {
  .pc {
    display: none !important;
  }
  #root {
    font-size: vw(28);
  }

  .main-content {
    padding-top: vw(120);
  }

  .sec {
    padding: vw(130) 0;
    &-inner {
      width: 100%;
      box-sizing: border-box;
      padding: 0 vw(30);
    }
    &-subtl {
      line-height: 1;
    }
    &-tl {
      font-size: vw(40);
      letter-spacing: vw(4);
      line-height: 1.5;
      margin-top: vw(24);
    }
    &-inner {
      width: 100%;
      box-sizing: border-box;
      padding: 0 vw(30);
      margin: 0 auto;
    }
  }
}
