@charset "utf-8";
@import '../_config/_mixin.css';
@import '../_config/_fonts.css';
@import '../_config/_media.css';
@import '../_config/_reset.css';

.ft {
  width: 100%;
  &-pagetop {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    background-color: $dark_gray;
    text-align: center;
    cursor: pointer;
    i {
      width: 20px;
      height: 20px;
      display: block;
      margin: 0 auto;
      transform: rotate(-90deg);
    }
  }
  &-nav {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-copy {
    display: block;
    text-align: center;
  }
}

@media (--pc) {
  .ft {
    padding-bottom: 40px;
    &-pagetop {
      line-height: 1;
      height: 100px;
      @mixin alphaHover;
      i {
        font-size: rem(15);
      }
      span {
        margin-top: 10px;
      }
    }
    &-nav {
      margin: 30px auto 24px;
      li {
        margin: 0 14px;
        a {
          @mixin alphaHover;
        }
        i {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
    &-copy {
      font-size: rem(12);
      letter-spacing: 1px;
    }
  }
}

@media (--sp) {
  .ft {
    padding-bottom: vw(80);
    &-pagetop {
      line-height: 1;
      height: vw(130);
      span {
        margin-top: vw(20);
      }
    }
    &-nav {
      margin: vw(75) auto vw(60);
      ul {
        box-sizing: border-box;
        padding: 0 vw(30);
      }
      li {
        a{
          white-space: nowrap;
        }
        &:nth-of-type(2) {
          margin: 0 vw(80);
        }
        &:nth-of-type(3) {
          i {
            display: inline-block;
            margin-left: vw(10);
          }
        }
      }
    }
    &-copy {
      font-size: vw(25);
      letter-spacing: 1px;
    }
  }
}
